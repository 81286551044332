/* ---- reset ---- */

* {
  margin: 0;
}

#particles-js {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.App {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
}
.animationWrapper {
  height: 75vh;
  width: 100vw;
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center;
  opacity: 1;
  -webkit-transition: opacity 0.5s ease-in-out;
  -moz-transition: opacity 0.5s ease-in-out;
  -ms-transition: opacity 0.5s ease-in-out;
  -o-transition: opacity 0.5s ease-in-out;
  transition: opacity 0.5s ease-in-out;
}
.fadeOut {
  opacity: 0;
  -webkit-transition: opacity 0.5s ease-in-out;
  -moz-transition: opacity 0.5s ease-in-out;
  -ms-transition: opacity 0.5s ease-in-out;
  -o-transition: opacity 0.5s ease-in-out;
  transition: opacity 0.5s ease-in-out;
}
.splash_title {
  font-family: Barlow;
  font-weight: 400;
  font-size: 32px;
  text-align: right;
}
.loginFormContainer {
  margin-top: 280px;
  margin-left: 160px;
  align-items: center;
}
.astronaut {
  align-self: flex-end;
  transform-origin: 50% 50%;
  animation-name: bounce-6;
  animation-timing-function: ease;
  animation-delay: 1s;
  animation-duration: 1.7s;
  animation-iteration-count: infinite;
  transform-origin: bottom;
}
@keyframes bounce-6 {
  0% {
    transform: scale(1, 1) translateY(0);
  }
  10% {
    transform: scale(1.1, 0.9) translateY(0);
  }
  30% {
    transform: scale(0.9, 1.1) translateY(-100px);
  }
  50% {
    transform: scale(1.05, 0.95) translateY(0);
  }
  57% {
    transform: scale(1, 1) translateY(-7px);
  }
  64% {
    transform: scale(1, 1) translateY(0);
  }
  100% {
    transform: scale(1, 1) translateY(0);
  }
}
.splash_title_sign_in {
  font-family: Barlow;
  font-weight: 500;
  font-size: 36px;
  text-align: left;
  color: #003c78;
  margin-bottom: 20px;
}
.splash_subtitle_sign_in {
  font-family: Barlow;
  font-weight: 500;
  font-size: 32px;
  text-align: left;
  color: #003c78;
  margin-bottom: 20px;
}
.splash_body_sign_in {
  font-size: 28px;
  line-height: 42px;
  font-family: Barlow;
  font-weight: 400;
  margin-bottom: 40px;
}
.container_content {
  max-width: 600px;
}
.planetContainer {
  width: 90%;
  display: flex;
  float: left;
  flex-shrink: 0;
  justify-content: center;
  flex-direction: column;
}
.planetName {
  width: 100%;
  font-family: Barlow;
  font-weight: bold;
  font-size: 21px;
  line-height: 42px;
  text-align: center;
  color: #003c78;
  text-transform: uppercase;
}
.planetName::after {
  content: '';
  width: 100%;
  height: 8px;
  display: block;
  background-color: #003c78;
}
.planet {
  height: 130px;
  width: 100%;
  object-fit: contain;
}
.bgImage2 {
  height: 100vh;
  position: absolute;
  left: 0;
  top: 0;
}
.bgImage1 {
  height: 31vh;
  position: absolute;
  right: 0;
  top: 0;
}
.innerImg {
  position: relative;
  width: 100%;
}
.topRightLogo {
  position: fixed;
  top: 45px;
  right: 70px;
  z-index: 20;
  width: 170px;
}
.logo_holder {
  width: 100%;
  max-width: 380px;
}
.logo_underline {
  width: 100%;
  height: 5px;
  margin-top: 36px;
  max-width: 380px;
  background-color: #003c78;
}
.rocketContainer {
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  width: 100%;
  bottom: 0px;
  margin-left: 60px;
  justify-content: center;
}
.innerImgContainer {
  width: 100%;
  height: 100%;
  position: relative;
}
.overlayScreen {
  height: 100vh;
  width: 100vw;
  align-items: center;
  justify-content: center;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}
.overlayScreenSignIn {
  width: 100%;
  margin: 0 auto;
  padding-top: 4rem;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-around;
  display: flex;
  z-index: 10;
}
@media (min-width: 1280px) {
  .form {
    padding: 20rem 13rem 8rem 0rem;
  }
}

.inputStyle {
  width: 332px;
  height: 48px;
  border-radius: 20px;
  outline: none;
  font-family: Barlow;
  font-weight: normal;
  font-size: 16px;
  text-align: left;
  color: #3e3e3e;
  box-sizing: border-box;
  padding-left: 20px;
  background: #fff;
  border: 1px solid #a6a6a6;
  display: block;
  margin-bottom: 20px;
}
.buttonStyle {
  width: 194px;
  height: 48px;
  border-radius: 20px;
  background: #003c78;
  font-family: Barlow;
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  color: #fff;
}
.rocket {
  height: 90vh;
  margin-bottom: -18vh;
  margin-right: -120px;
  z-index: 1;
}
@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0) rotate(0deg);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0) rotate(1deg);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0) rotate(2deg);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0) rotate(0deg);
  }
  100% {
    transform: translate3d(0, 0, 0);
    margin-bottom: -16vh;
  }
}
.books {
  height: 18vh;
  margin-left: -120px;
}
.rocketContainer.active .books {
  animation: name;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  animation-delay: 0.3s;
}
.rocketContainer.active .rocket {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}
@keyframes name {
  from {
    margin-bottom: 0;
  }
  to {
    margin-bottom: -18vh;
  }
}
.bgImage3 {
  height: 76vh;
  position: absolute;
  left: -20px;
  top: 2vh;
}
