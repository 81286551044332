:root {
  --main-yellow: #ffca00;
  --main-white: #fff;
  --main-lightBlue: #00a8e9;
  --main-darkBlue: #003c78;
  --main-lightGray: #707070;
  --main-gray: #3e3e3e;
}

body {
  @media only screen and (max-width: 1224px) {
    overflow: scroll !important;
    -webkit-overflow-scrolling: touch;
  }
}

.bold {
  font-weight: 700 !important;
}

.semibold {
  font-weight: 500 !important;
}

.regular {
  font-weight: 400 !important;
}

.h1 {
  font-size: 36px;
  font-family: Barlow;
  font-weight: 500;
}

.h2 {
  font-size: 32px;
  font-family: Barlow;
  font-weight: 400;
}

.h3 {
  font-size: 28px;
  font-family: Barlow;
  font-weight: 400;
}

.s1 {
  font-size: 21px;
  font-family: Barlow;
  line-height: 26px;
  font-weight: 700;
}

.s1Alt {
  font-size: 24px;
  line-height: 36px;
  font-family: Barlow;
  font-weight: 400;
}

.s2 {
  font-size: 18px;
  font-family: Barlow;
}

.body1 {
  font-size: 16px;
  font-family: Barlow;
}

.body1Alt {
  font-size: 16px;
  font-family: Barlow;
  font-weight: 700;
}

.body2 {
  font-size: 14px;
  font-family: Barlow;
}

.body3 {
  font-size: 12px;
  font-family: Barlow;
}

.bodyTiny {
  font-size: 10px;
  font-family: Barlow;
}

.mt15 {
  margin-top: 15px;
}

.mt30 {
  margin-top: 30px;
}

.mt50 {
  margin-top: 50px;
}

.tuc {
  text-transform: uppercase;
}

.tac {
  text-align: center;
}

.tar {
  text-align: right;
}

/* Text Colors */

.color.yellow {
  color: var(--main-yellow);
}

.color.darkBlue {
  color: var(--main-darkBlue);
}

.color.lightBlue {
  color: var(--main-lightBlue);
}

.color.gray {
  color: var(--main-gray);
}

.color.lightGray {
  color: var(--main-lightGray);
}

.color.white {
  color: var(--main-white);
}

/* Animation */

.fadeOut {
  opacity: 0;
  -webkit-transition: opacity 0.5s ease-in-out;
  -moz-transition: opacity 0.5s ease-in-out;
  -ms-transition: opacity 0.5s ease-in-out;
  -o-transition: opacity 0.5s ease-in-out;
  transition: opacity 0.5s ease-in-out;
}
